section.header-main {
  background-image: url("../../images/Illustration.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  min-height: 25rem;
}
.welcome-h1 {
  font-size: 60px;
  font-weight: 700;
}
.headerMainForm input.form-control {
  height: 3rem;
  margin: 1rem 0;
  width: 25rem;
}
.welcome-p {
  width: 25rem;
}

.header_main_div {
  min-height: 20rem;
}

.header_main_div h1,
h1.trust {
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 1px;
}

@media (min-width: 992px) {
  .header_main_div h1,
  h1.trust {
    font-size: 3rem;
  }
  .header_main_div h4 {
    font-size: 1.7rem;
  }
}

button.searchFriends {
  border: none;
  outline: none;
}
input.searchFriends.form-control {
  height: 3rem;
  box-shadow: 2px 2px 6px 5px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 576px) {
  .welcome-h1 {
    font-size: 3rem;
    font-weight: 700;
  }
}
@media screen and (max-width: 768px) {
  input.form-control {
    width: 100%;
  }
  .welcome-p {
    width: 100%;
  }
  .headerMainForm input.form-control {
    width: 100%;
  }
}
