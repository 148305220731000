.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000000000;
  background-color: #2e6bd8;
}
span.navbar-brand a {
  text-decoration: none;
}

.navbar-nav > a {
  margin: 0 1rem;
  font-weight: 300;
  color: white;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 0.5px;
  position: relative;
}

.navbar-nav > a:hover {
  color: #ffff3f;
  text-decoration: none;
}
.navbar-nav > a.active {
  border-bottom: 1px solid #ffff3f;
  color: #ffff3f;
}
img.logo.img-fluid {
  width: 20rem;
}

/* test code  */
.navbar-nav .header_mid_div > a,
.navbar-nav .header_btn_div > a {
  color: white !important;
  margin: 0 1rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 0.5px;
  position: relative;
  font-size: 14px;
  transition: 0.5s;
}

@media (max-width: 992px) {
  .navbar-nav .header_mid_div > a,
  .navbar-nav .header_btn_div > a {
    margin: 1rem 0;
  }
  .header_mid_div.mr-5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .header_btn_div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .header_btn_div button.primary {
    margin-top: 1rem;
    margin-bottom: 3rem;
    margin-left: 0;
  }
}

a:hover {
  text-decoration: none !important;
}
.navbar-nav .header_mid_div > a:hover,
.dropbtn:hover {
  color: var(--text-color3) !important;
}
.navbar-nav .header_mid_div > a.active {
  border-bottom: 1px solid var(--text-color3);
  color: var(--text-color3) !important;
}

.dropbtn {
  background-color: transparent;
  color: white;
  outline: none !important;
  font-size: 16px;
  border: none;
  margin: 0 1rem;
  font-weight: 600;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: 0.5s;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

dl,
ol,
ul {
  margin-bottom: 0 !important;
}
a.headerLink {
  background: none;
  text-align: center;
}

nav ul {
  float: right;
}
nav ul li {
  list-style: none;
}
nav ul li > a {
  color: white !important;
  display: block;
  padding: 0 20px;
  background: var(--background-color);
  transition: 0.5s;
  font-weight: 600;
}
nav ul li a:hover,
nav ul li a.active {
  color: var(--text-color3) !important;
  cursor: pointer;
}
nav ul ul {
  background-color: #207bff;
  position: absolute;
  top: 50px;
  border-top: 3px solid #23dbdb;
  opacity: 0;
  visibility: hidden;
}

nav ul ul li {
  width: 15rem;
  display: list-item;
  position: relative;
  border-top: none;
  list-style: none;
}
nav ul ul li a {
  line-height: 50px;
}
nav ul ul ul {
  border-top: none;
}
nav ul ul ul li {
  position: relative;
  top: -70px;
  left: 150px;
}
nav ul ul li a i {
  margin-left: 45px;
}
.navbar-toggler {
  background: var(--text-color3) !important;
  outline: none !important;
}

@media (min-width: 992px) {
  nav ul li {
    display: inline-block;
    list-style: none;
  }
  nav ul li:hover > ul {
    top: 70px;
    opacity: 1;
    visibility: visible;
    transition: 0.3s linear;
  }
  a.headerLink {
    padding: 1rem 20px;
  }
  .ml-auto.navbar-nav {
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-gap: 4rem;
  }
  .header_mid_div {
    display: flex;
    align-items: center;
  }
  .header_btn_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .header_btn_div button.primary {
    margin-left: 2rem;
  }
}
