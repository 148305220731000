section.achievement {
  /* background: #fafffd; */
  padding: 7rem 0;
}
.singleAchieve {
  border-radius: 0 4rem;
  padding: 2rem 3rem;
  margin: 1rem;
  transition: all 0.6s ease;
  background: transparent;
  border: 2px solid #fe3c00;
}
.singleAchieve:hover {
  background: #fe3c00;
  color: white;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px #e8f8f2;
}
.col-md-5.d-flex.flex-column.justify-content-center.align-items-start h3 {
  font-size: 36px;
  /* font-family: 'Yeseva One', cursive; */
  margin-bottom: 1rem;
}
.col-md-5.d-flex.flex-column.justify-content-center.align-items-start p {
  font-size: 12px;
  line-height: 25px;
  margin-bottom: 2rem;
}
.details h2 {
  font-size: 40px;
  font-weight: 700;
}
img.achieveImg {
  width: 4rem;
  margin-right: 1rem;
}
