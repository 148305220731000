@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000000000;
  background-color: #2e6bd8;
}
span.navbar-brand a {
  text-decoration: none;
}

.navbar-nav > a {
  margin: 0 1rem;
  font-weight: 300;
  color: white;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 0.5px;
  position: relative;
}

.navbar-nav > a:hover {
  color: #ffff3f;
  text-decoration: none;
}
.navbar-nav > a.active {
  border-bottom: 1px solid #ffff3f;
  color: #ffff3f;
}
img.logo.img-fluid {
  width: 20rem;
}

/* test code  */
.navbar-nav .header_mid_div > a,
.navbar-nav .header_btn_div > a {
  color: white !important;
  margin: 0 1rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 0.5px;
  position: relative;
  font-size: 14px;
  transition: 0.5s;
}

@media (max-width: 992px) {
  .navbar-nav .header_mid_div > a,
  .navbar-nav .header_btn_div > a {
    margin: 1rem 0;
  }
  .header_mid_div.mr-5 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .header_btn_div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .header_btn_div button.primary {
    margin-top: 1rem;
    margin-bottom: 3rem;
    margin-left: 0;
  }
}

a:hover {
  text-decoration: none !important;
}
.navbar-nav .header_mid_div > a:hover,
.dropbtn:hover {
  color: var(--text-color3) !important;
}
.navbar-nav .header_mid_div > a.active {
  border-bottom: 1px solid var(--text-color3);
  color: var(--text-color3) !important;
}

.dropbtn {
  background-color: transparent;
  color: white;
  outline: none !important;
  font-size: 16px;
  border: none;
  margin: 0 1rem;
  font-weight: 600;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: 0.5s;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

dl,
ol,
ul {
  margin-bottom: 0 !important;
}
a.headerLink {
  background: none;
  text-align: center;
}

nav ul {
  float: right;
}
nav ul li {
  list-style: none;
}
nav ul li > a {
  color: white !important;
  display: block;
  padding: 0 20px;
  background: var(--background-color);
  transition: 0.5s;
  font-weight: 600;
}
nav ul li a:hover,
nav ul li a.active {
  color: var(--text-color3) !important;
  cursor: pointer;
}
nav ul ul {
  background-color: #207bff;
  position: absolute;
  top: 50px;
  border-top: 3px solid #23dbdb;
  opacity: 0;
  visibility: hidden;
}

nav ul ul li {
  width: 15rem;
  display: list-item;
  position: relative;
  border-top: none;
  list-style: none;
}
nav ul ul li a {
  line-height: 50px;
}
nav ul ul ul {
  border-top: none;
}
nav ul ul ul li {
  position: relative;
  top: -70px;
  left: 150px;
}
nav ul ul li a i {
  margin-left: 45px;
}
.navbar-toggler {
  background: var(--text-color3) !important;
  outline: none !important;
}

@media (min-width: 992px) {
  nav ul li {
    display: inline-block;
    list-style: none;
  }
  nav ul li:hover > ul {
    top: 70px;
    opacity: 1;
    visibility: visible;
    transition: 0.3s linear;
  }
  a.headerLink {
    padding: 1rem 20px;
  }
  .ml-auto.navbar-nav {
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-gap: 4rem;
  }
  .header_mid_div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .header_btn_div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
  }
  .header_btn_div button.primary {
    margin-left: 2rem;
  }
}

section.header-main {
  background-image: url(/static/media/Illustration.95172eef.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  min-height: 25rem;
}
.welcome-h1 {
  font-size: 60px;
  font-weight: 700;
}
.headerMainForm input.form-control {
  height: 3rem;
  margin: 1rem 0;
  width: 25rem;
}
.welcome-p {
  width: 25rem;
}

.header_main_div {
  min-height: 20rem;
}

.header_main_div h1,
h1.trust {
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 1px;
}

@media (min-width: 992px) {
  .header_main_div h1,
  h1.trust {
    font-size: 3rem;
  }
  .header_main_div h4 {
    font-size: 1.7rem;
  }
}

button.searchFriends {
  border: none;
  outline: none;
}
input.searchFriends.form-control {
  height: 3rem;
  box-shadow: 2px 2px 6px 5px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 576px) {
  .welcome-h1 {
    font-size: 3rem;
    font-weight: 700;
  }
}
@media screen and (max-width: 768px) {
  input.form-control {
    width: 100%;
  }
  .welcome-p {
    width: 100%;
  }
  .headerMainForm input.form-control {
    width: 100%;
  }
}

section.achievement {
  /* background: #fafffd; */
  padding: 7rem 0;
}
.singleAchieve {
  border-radius: 0 4rem;
  padding: 2rem 3rem;
  margin: 1rem;
  transition: all 0.6s ease;
  background: transparent;
  border: 2px solid #fe3c00;
}
.singleAchieve:hover {
  background: #fe3c00;
  color: white;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px #e8f8f2;
}
.col-md-5.d-flex.flex-column.justify-content-center.align-items-start h3 {
  font-size: 36px;
  /* font-family: 'Yeseva One', cursive; */
  margin-bottom: 1rem;
}
.col-md-5.d-flex.flex-column.justify-content-center.align-items-start p {
  font-size: 12px;
  line-height: 25px;
  margin-bottom: 2rem;
}
.details h2 {
  font-size: 40px;
  font-weight: 700;
}
img.achieveImg {
  width: 4rem;
  margin-right: 1rem;
}

footer.footer {
  padding-top: 5rem;
  /* background: #fafffd; */
}
.col-md-7.mx-auto.d-flex.flex-column.align-items-center {
  margin-bottom: 8rem;
}
.col-md-7.mx-auto.d-flex.flex-column.align-items-center h3 {
  font-size: 36px;
  font-family: "Yeseva One", cursive;
  margin-bottom: 1rem;
  text-align: center;
}
.col-md-7.mx-auto.d-flex.flex-column.align-items-center p {
  text-align: center;
}
input.subscribeInput {
  height: 3rem;
  width: 30rem;
  border: none;
  padding: 0 2rem;
  border-radius: 5px;
  outline: none;
  color: gray;
  margin-right: 1rem;
  box-shadow: 2px 2px 6px 5px rgba(0, 0, 0, 0.25);
}
input.subscribeInput:focus {
  transition: 1s ease-in-out;
}
.mt-4.d-flex.justify-content-center.align-items-center .contactBtn {
  border-radius: 5px;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: 400;
  height: 3rem;
  padding: 0.65rem 1.8rem;
  transition: 0.6s ease;
  background: #fe3c00;
  transition: 0.1s ease;
  box-shadow: 2px 2px 6px 5px rgba(0, 0, 0, 0.25);
}

.footerLeft img.footerLogo {
  width: 8rem;
  margin-bottom: 2rem;
}
img.socialImg {
  width: 1.5rem;
  margin: 0 0.5rem;
}
p.footerRightP {
  font-size: 14px;
  margin-bottom: 0.5rem;
  font-weight: 500;
}
p.footerRightP:hover {
  color: #ffff3f;
}
img.img-fluid.socialImg:hover {
  -webkit-filter: brightness(50);
          filter: brightness(50);
  cursor: pointer;
  transition: 1s ease;
}

p.text-center.mb-0 {
  background-color: #333333;
  padding: 1rem 0;
}
@media screen and (max-width: 768px) {
  .mt-4.d-flex.justify-content-center.align-items-center {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  input.subscribeInput {
    width: 100%;
    margin-bottom: 1rem;
  }
  .mt-4.d-flex.justify-content-center.align-items-center .contactBtn {
    position: relative;
  }
  .col-md-7.mx-auto.d-flex.flex-column.align-items-center {
    margin-bottom: 2rem;
  }

  .col-md-4.rightFooterDiv {
    margin: 1rem 0px;
  }
  p.footerRightP {
    text-align: center;
  }

  .col-md-4.d-flex.flex-column.footerLeft.justify-content-center.align-items-center {
    margin: 2rem 0;
  }
}

section.home {
  background-color: #2e6bd8;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: white;
}
.buttonElement {
  border: none;
  background-color: #fe3c00;
  background-size: 200% auto;
  color: white !important;
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  text-decoration: none !important;
  padding: 0.8rem 2rem;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 2px 2px 6px 5px rgba(0, 0, 0, 0.25);
}
.buttonElement:hover {
  background-position: 100%;
}

.rstore-domain-search {
  position: absolute;
  top: 32rem;
  z-index: 10000;
  width: 75%;
  left: 0;
  margin: 0 auto;
  right: 0;
}

span.salePrice strong,
.domain-name,
p.not-available {
  color: black;
}
.domain-result {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 1rem 0;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.rstore-message {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  min-width: 15rem;
}
.result-content {
  background-color: white;
  padding: 2rem;
}
form.continue-form {
  background-color: white;
  padding: 0 2rem;
  padding-top: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

