footer.footer {
  padding-top: 5rem;
  /* background: #fafffd; */
}
.col-md-7.mx-auto.d-flex.flex-column.align-items-center {
  margin-bottom: 8rem;
}
.col-md-7.mx-auto.d-flex.flex-column.align-items-center h3 {
  font-size: 36px;
  font-family: "Yeseva One", cursive;
  margin-bottom: 1rem;
  text-align: center;
}
.col-md-7.mx-auto.d-flex.flex-column.align-items-center p {
  text-align: center;
}
input.subscribeInput {
  height: 3rem;
  width: 30rem;
  border: none;
  padding: 0 2rem;
  border-radius: 5px;
  outline: none;
  color: gray;
  margin-right: 1rem;
  box-shadow: 2px 2px 6px 5px rgba(0, 0, 0, 0.25);
}
input.subscribeInput:focus {
  transition: 1s ease-in-out;
}
.mt-4.d-flex.justify-content-center.align-items-center .contactBtn {
  border-radius: 5px;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: 400;
  height: 3rem;
  padding: 0.65rem 1.8rem;
  transition: 0.6s ease;
  background: #fe3c00;
  transition: 0.1s ease;
  box-shadow: 2px 2px 6px 5px rgba(0, 0, 0, 0.25);
}

.footerLeft img.footerLogo {
  width: 8rem;
  margin-bottom: 2rem;
}
img.socialImg {
  width: 1.5rem;
  margin: 0 0.5rem;
}
p.footerRightP {
  font-size: 14px;
  margin-bottom: 0.5rem;
  font-weight: 500;
}
p.footerRightP:hover {
  color: #ffff3f;
}
img.img-fluid.socialImg:hover {
  filter: brightness(50);
  cursor: pointer;
  transition: 1s ease;
}

p.text-center.mb-0 {
  background-color: #333333;
  padding: 1rem 0;
}
@media screen and (max-width: 768px) {
  .mt-4.d-flex.justify-content-center.align-items-center {
    flex-direction: column;
  }
  input.subscribeInput {
    width: 100%;
    margin-bottom: 1rem;
  }
  .mt-4.d-flex.justify-content-center.align-items-center .contactBtn {
    position: relative;
  }
  .col-md-7.mx-auto.d-flex.flex-column.align-items-center {
    margin-bottom: 2rem;
  }

  .col-md-4.rightFooterDiv {
    margin: 1rem 0px;
  }
  p.footerRightP {
    text-align: center;
  }

  .col-md-4.d-flex.flex-column.footerLeft.justify-content-center.align-items-center {
    margin: 2rem 0;
  }
}
