@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: white;
}
.buttonElement {
  border: none;
  background-color: #fe3c00;
  background-size: 200% auto;
  color: white !important;
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  text-decoration: none !important;
  padding: 0.8rem 2rem;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 2px 2px 6px 5px rgba(0, 0, 0, 0.25);
}
.buttonElement:hover {
  background-position: 100%;
}

.rstore-domain-search {
  position: absolute;
  top: 32rem;
  z-index: 10000;
  width: 75%;
  left: 0;
  margin: 0 auto;
  right: 0;
}

span.salePrice strong,
.domain-name,
p.not-available {
  color: black;
}
.domain-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  flex-wrap: wrap;
}
.rstore-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 15rem;
}
.result-content {
  background-color: white;
  padding: 2rem;
}
form.continue-form {
  background-color: white;
  padding: 0 2rem;
  padding-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
